import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
/*const notion = new Client({ auth: process.env.GATSBY_NOTION_KEY });
const databaseId = process.env.GATSBY_NOTION_ID;*/
export const Feedback: React.FC<{
  children?: any;
}> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [suggest, setSuggest] = useState<string>("");
  const sendFeedback = async (e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();
    console.log(suggest);
    let formData = { "form-name": "Suggestions", suggest };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData),
    })
      .then(() =>
        toast({
          title: "Submitted.",
          description: "Thank you for your feedback.",
          status: "success",
          duration: 6000,
          isClosable: true,
        })
      )
      .catch(() =>
        toast({
          title: "Submission Failed",
          description: "Your submission failed.",
          status: "error",
          duration: 6000,
          isClosable: true,
        })
      );
  };

  return (
    <>
      <Button onClick={onOpen} bg={"white"} color={"black"}>
        Suggestions
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignContent={"center"} mx={"auto"}>
            {" "}
            Suggestions
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={10}>
            <Text fontWeight={"semibold"}>
              Suggest a category of subreddits to add, or leave some feedback.
            </Text>

            <Input
              placeholder={"Type here"}
              value={suggest}
              onChange={(e) => setSuggest(e.target.value)}
              onSubmit={(e) => sendFeedback(e)}
            />
            <Center>
              <Button
                type={"submit"}
                onClick={(e) => sendFeedback(e)}
                m={2}
                bg={"#3c7eff"}
                color={"white"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
              >
                Submit
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
