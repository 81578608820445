import snoowrap from "snoowrap";
//Change values before making live.
//Maybe go back to reddit updates app.
//Have free tool that allows search for mentions.
//Add later update to give more detailed information.
//Add later update to listen for notifications.
/*
const r = new snoowrap({
    userAgent: 'Lokal_Reddit_Script-v1.1',
    clientId: 'gUqpW3TXduc8QA',
    clientSecret: '24rIeFWY5TdehUN-V0IQx7M9q-xr9w',
    refreshToken: ''
});*/
export const searchReddit = (
  type: string = "relevance",
  scope: number = 30,
  terms: string,
  more: string,
  date: string,
  nsfw: boolean
) => {
  let off = "off";
  nsfw ? (off = "on") : (off = "off");
  return fetch(
    "https://www.reddit.com/search.json?q=" +
      terms +
      "&limit=" +
      scope +
      "&sort=" +
      type +
      "&include_over_18=off&raw_json=1&after=" +
      more +
      "&t=" +
      date
  )
    .then((res) => res.json())
    .then((data) => data.data.children)
    .catch((e) => console.log(e));
};
/*type User = { id: string, name: string, photo:string }
type Tweets = {username:string}
const client = new Twitter({
    appKey: 'hDiWRPbIsm8SfeJZfNSMEFgGF',
    appSecret: 'q71UL82bsO5g0fdIaFkI6ansKxhzkUTqTZ92VSnNdQOocwrNgU',
});
export const useAccount = (twitterUser: string, scope: number) => {
    //Make function that validates the user account is correct format.
    const account = { photo: null, username: null, users: {} }
    /*const userID = client.get('user.fields', { id: twitterUser }).then((val) => { account.photo = (val as User).photo; return client.get('users?expansions', { id: (val as User).id })}).then((res) => { let data = (res as Tweets); console.log(data);  account.username = data.username;}).catch((e)=>console.log(e));
    console.log(account)
    return account;
}
*/
export const selectOptions = (list: object): Array<any> => {
  let result = [];
  for (let item in list) {
    if (list.hasOwnProperty(item)) {
      result.push({ label: item, value: list[item] });
    }
  }
  return result;
};
