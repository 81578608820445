import React, { useState, useEffect } from "react";
import { HStack, Text } from "@chakra-ui/react";
import {
  FaClock,
  FaFireAlt,
  FaRegSmileBeam,
  FaTwitter,
  FaRegAngry,
  FaRegMehBlank,
} from "react-icons/fa";
export const PostAnalysis: React.FC<{
  users: number;
  comments: number;
  created: number;
  upvoteRatio: number;
  url: string;
  subreddit: string;
  subscribers: number;
}> = ({
  users,
  comments,
  created,
  upvoteRatio,
  url,
  subreddit,
  subscribers,
}) => {
  const [angry, setAngry] = useState<boolean>(false);
  const [recent, setRecent] = useState<boolean>(false);
  const [tweet, setTweet] = useState<boolean>(false);
  const [popular, setPopular] = useState<boolean>(false);
  let date = Date.now();
  useEffect(() => {
    //Handle deciding if angry, active, tweet here.

    setAngry(upvoteRatio <= 0.6);
    let rc = date / 1000 - created <= 86400;
    setRecent(rc);
    let tw = url.includes("twitter.com/");
    setTweet(tw);
    let val = users >= 100 || comments >= 100;
    setPopular(val);
  }, [date]);
  return (
    <HStack wrap={"wrap"} p={2} m={2} justifyContent={"normal"}>
      {angry ? (
        <HStack
          bg={"red"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaRegAngry color={"white"} />
          <Text color={"white"}>Polarizing</Text>
        </HStack>
      ) : (
        <HStack
          bg={"green"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaRegSmileBeam color={"white"} />
          <Text color={"white"}>Agreeable</Text>
        </HStack>
      )}
      {popular ? (
        <HStack
          bg={"orange"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaFireAlt color={"white"} />
          <Text color={"white"}>High Activity</Text>
        </HStack>
      ) : (
        <HStack
          bg={"orange"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaRegMehBlank color={"white"} />
          <Text color={"white"}>Low Activity</Text>
        </HStack>
      )}
      {tweet ? (
        <HStack
          bg={"skyblue"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaTwitter color={"white"} />
          <Text color={"white"}>Tweet</Text>
        </HStack>
      ) : (
        <></>
      )}
      {recent ? (
        <HStack
          bg={"#f5f5f5"}
          alignContent={"center"}
          borderRadius={"md"}
          p={1}
          my={1}
        >
          <FaClock />
          <Text> Recent</Text>
        </HStack>
      ) : (
        <></>
      )}
      <HStack
        bg={"orange"}
        alignContent={"center"}
        borderRadius={"md"}
        p={1}
        my={1}
      >
        <Text color={"white"}>{users} upvotes</Text>
      </HStack>
      <HStack
        bg={"yellow"}
        alignContent={"center"}
        borderRadius={"md"}
        p={1}
        my={1}
      >
        <Text>{comments} comments</Text>
      </HStack>

      <HStack
        bg={"#054ada"}
        borderRadius={"md"}
        p={1}
        alignContent={"center"}
        my={2}
      >
        <Text color={"white"}>
          r/{subreddit} has {subscribers} subscribers
        </Text>
      </HStack>
    </HStack>
  );
};
