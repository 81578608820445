import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Input,
  Center,
  Img,
  VStack,
  FormControl,
  FormHelperText,
  HStack,
  Button,
  Stack,
  Switch,
  FormLabel,
} from "@chakra-ui/react";
import { searchReddit, selectOptions } from "../utils/hooks";
import { Helmet } from "react-helmet";
import { PostAnalysis } from "../components/analysis";
import logo from "../images/logo.png";
import { navigate } from "gatsby-link";
import sublist from "../utils/sublist.json";
import { Select } from "chakra-react-select";
import poplist from "../utils/poplist.json";
import { Feedback } from "../components/feedback";
//Add small analytics dashboard over search results.
const IndexPage = () => {
  const [list, setList] = useState<Array<any>>([]);
  const [searched, setSearched] = useState<boolean>(false);
  const [advanced, setAdvanced] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [sortBy, setSort] = useState<string>("relevance");
  const [loading, setLoading] = useState<boolean>(false);
  const [lastPost, setLast] = useState<string>("");
  const [scrollMore, setScroll] = useState<boolean>(false);
  const [age, setAge] = useState<string>("all");
  const [chosenSubs, setChosen] = useState<Array<any>>([]);
  const [safesearch, setSafesearch] = useState<boolean>(true);
  const options = selectOptions(sublist);

  const sendFeedback = async (e: React.FormEvent) => {
    e.preventDefault();
  };
  const search = async (
    e: React.FormEvent,
    more: string = null,
    terms: string = value
  ) => {
    e.preventDefault();
    setScroll(true);
    setLoading(true);
    let temp = " ";
    let subs = "";
    let arr = [];
    chosenSubs.map((item, index) => {
      return arr.push.apply(arr, item.value.split(" "));
    });
    let filtered = arr.filter((item) => {
      if (item != "") {
        return item;
      }
    });
    for (let i = 0; i < filtered.length && i <= 17; i++) {
      temp += filtered[i] + " OR ";
    }
    chosenSubs[0] ? (subs = " AND (" + temp.slice(0, -3) + ")") : null;
    let result = await searchReddit(
      sortBy,
      30,
      terms + subs,
      more,
      age,
      safesearch
    );
    window.gtag
      ? window.gtag("event", "search", { terms, subs, sortBy })
      : null;
    more ? setList(list.concat(result)) : setList(result);
    //Used the spread operator because React does not automatically re-render. Javascript interprets the new array as the same as the old array without the spread operator.
    let final = result[result.length - 1];
    final ? setLast(final.data.name) : null;
    setSearched(true);
    setLoading(false);
    setScroll(false);
  };
  useEffect(() => {}, [list]);
  return (
    <Box bg={"#508DC7"} minH={"100vh"} h={"100%"} w={"100%"}>
      <Helmet title={"Mentions"}>
        <meta
          name={"description"}
          content={"Advanced search made easy for Reddit."}
        />
        <meta name={"image"} content={"src/images/icon.png"} />
        <meta name={"siteUrl"} content={"https://www.mentionsapp.com"} />
      </Helmet>
      <Stack
        bg={searched ? "#508DC7" : "transparent"}
        py={3}
        mb={2}
        mx={2}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Img
          src={logo}
          objectFit={"cover"}
          maxW={{ base: "30%", md: "12%" }}
          alignSelf={"center"}
          mt={"0vh"}
          ml={{ base: 0, md: 5 }}
          mx={{ base: 2, md: 5 }}
          onClick={() => navigate("/")}
        />
        <Box mr={{ base: 0, md: 5 }} mx={{ base: 2, md: 5 }}>
          <form
            name={"Suggestions"}
            data-netlify={true}
            onSubmit={(e) => sendFeedback(e)}
          >
            <input type={"hidden"} name={"suggest"} />
            <Feedback />
          </form>
        </Box>
      </Stack>
      <Center>
        <Box
          mt={{ base: "5vh", md: "15vh" }}
          maxW={{ base: "95%", md: "50%" }}
          mb={5}
          hidden={searched}
        >
          <Text fontWeight={"bold"} fontSize={"3em"}>
            Find great Reddit posts.
          </Text>
          <Text fontWeight={"semibold"} fontSize={"1.2em"}>
            Search Reddit and find the most engaged discussions, discussions in
            active communities, and insights about how Redditors feel about a
            post.
          </Text>
        </Box>
      </Center>
      <form onSubmit={(e) => search(e)}>
        <Stack
          direction={"column"}
          maxW={{ base: "95%", md: "50%" }}
          mx={"auto"}
          bg={"#f5f5f5"}
          borderRadius={"md"}
          p={2}
        >
          <FormControl>
            <Input
              variant={"filled"}
              id={"search-value"}
              alignSelf={"center"}
              p={5}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Search Reddit"
            />
            <FormHelperText />
          </FormControl>

          <Select
            isMulti
            id={"subreddit-value"}
            options={options}
            placeholder={"Choose a community"}
            tagVariant={"solid"}
            colorScheme={"blue"}
            color={"black"}
            alignSelf={"center"}
            value={chosenSubs}
            onChange={setChosen}
            selectedOptionColor={"#black"}
          />
          <HStack>
            <HStack w={"70%"} wrap={"wrap"}>
              <Text fontWeight={"semibold"}>Popular:</Text>
              {poplist.map((item, index) => (
                <Button
                  size={"sm"}
                  borderBottom={"blue"}
                  borderBottomStyle={"solid"}
                  borderBottomRadius={"sm"}
                  px={0}
                  key={"popular-button-" + index}
                  onClick={(e) => {
                    setValue(item.value);
                    search(e, null, item.value);
                  }}
                  variant={"ghost"}
                >
                  {item.label}
                </Button>
              ))}
            </HStack>
            <Box ml={"auto"} p={5}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="safesearch" mb="0" ml={"auto"}>
                  Safesearch
                </FormLabel>
                <Switch
                  id="safesearch"
                  isChecked={safesearch}
                  onChange={() => setSafesearch(!safesearch)}
                  colorScheme={"green"}
                />
              </FormControl>
            </Box>
          </HStack>
        </Stack>

        <Center>
          <Stack direction={"row"} mt={searched ? 0 : 2}>
            <Button
              type={"submit"}
              display={"flex"}
              hidden={searched}
              bg={"#3c7eff"}
              color={"white"}
              _active={{
                bg: "#3c7eff",
                filter: "brightness(0.75)",
                transform: "scale(0.98)",
                color: "white",
              }}
              isLoading={loading}
            >
              Search
            </Button>
          </Stack>
          <VStack>
            <HStack mt={"2"} hidden={!searched} wrap={"wrap"}>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setSort("relevance");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={sortBy == "relevance"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Relevance
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setSort("hot");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={sortBy == "hot"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Hot
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setSort("new");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={sortBy == "new"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                New
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setSort("top");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={sortBy == "top"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Top
              </Button>
            </HStack>
            <HStack mt={"2"} hidden={!searched} wrap={"wrap"}>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("all");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "all"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                All Time
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("year");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "year"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Last Year
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("month");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "month"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Last Month
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("week");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "week"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Week
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("day");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "day"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Day
              </Button>
              <Button
                type={"submit"}
                onClick={(e) => {
                  setAge("hour");
                }}
                bg={"#3c7eff"}
                color={"white"}
                isActive={age == "hour"}
                _active={{
                  bg: "#3c7eff",
                  filter: "brightness(0.75)",
                  transform: "scale(0.98)",
                  color: "white",
                }}
                isLoading={loading}
              >
                Hour
              </Button>
            </HStack>
          </VStack>
        </Center>
      </form>
      <Center>
        <Box w={{ base: "85%", md: "75%", lg: "70%" }}>
          {list != null && list[0] ? (
            list.map((post, index) => (
              <a
                href={"http://www.reddit.com" + post.data.permalink}
                target={"_blank"}
                key={"a-" + index}
              >
                {" "}
                <Stack
                  direction={
                    post.data.preview || post.data.media_metadata
                      ? "row"
                      : "column"
                  }
                  borderRadius={"md"}
                  m={5}
                  key={"Stack-" + index}
                  bg={"#ffffff"}
                  py={5}
                  flexWrap={{ base: "wrap", md: "inherit" }}
                  px={5}
                  justifyContent={{
                    base: "space-evenly",
                    md: "space-between",
                  }}
                >
                  <VStack>
                    <Text
                      key={"text-" + index}
                      textAlign={"left"}
                      alignSelf={"start"}
                      color="black"
                      fontWeight={"semibold"}
                      mx={"2"}
                      size="100%"
                    >
                      {post.data.title}
                    </Text>
                    <PostAnalysis
                      users={post.data.ups}
                      comments={post.data.num_comments}
                      upvoteRatio={post.data.upvote_ratio}
                      created={post.data.created_utc}
                      url={post.data.url + post.data.domain}
                      subreddit={post.data.subreddit}
                      subscribers={post.data.subreddit_subscribers}
                    />
                  </VStack>
                  {post.data.preview ? (
                    <Img
                      key={"img-" + index}
                      objectFit={"cover"}
                      src={post.data.preview.images[0].source.url}
                      alignSelf={"center"}
                      mb={5}
                      w={"15vh"}
                    />
                  ) : post.data.media_metadata ? (
                    <Img
                      key={"img-" + index}
                      objectFit={"cover"}
                      src={post.data.thumbnail}
                      alignSelf={"center"}
                      mb={5}
                      w={"15vh"}
                    />
                  ) : (
                    <Text
                      key={"self-text-" + index}
                      textAlign={"left"}
                      alignSelf={"start"}
                      color="black"
                      mx={"2"}
                      size="100%"
                    >
                      {post.data.selftext.length > 280
                        ? post.data.selftext.slice(0, 280) + "..."
                        : post.data.selftext}
                    </Text>
                  )}
                </Stack>
              </a>
            ))
          ) : (
            <Box h={"40vh"} p={10} hidden={!searched}>
              <Center>
                <Text> No results found</Text>
              </Center>
            </Box>
          )}
          <Center>
            <Button
              bg={"#3c7eff"}
              color={"white"}
              onClick={(e) => search(e, lastPost)}
              isLoading={scrollMore}
              hidden={!searched || !list[0]}
            >
              Load More...
            </Button>
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default IndexPage;
